import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import moment from 'moment'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import SvgIcon from '@material-ui/core/SvgIcon'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'

import WrmIcon from '../WrmIcon'
import { FEATURE_PROPERTIES, DrawnLayerProperties } from '../../utils/constants'
import EditPropertiesDialog from './EditPropertiesDialog'
import { useCollectionStore } from '../../state/collectionStore'
import { useCommonStore } from '../../state/commonStore'
import { useUiStore } from '../../state/uiStore'
import MeasurementsManager from '../../services/MeasurementsManager'
import useGroupItemsUtils from '../../hooks/useGroupItemsUtils'
import useFeatureCollection from '../../hooks/useFeatureCollection'
import FeatureService from '../../services/FeatureService'
import CultivationService from '../../services/CultivationService'
import ConsumerService from '../../services/ConsumerService'
import SwitchSlotService from '../../services/SwitchSlotService'

const useStyles = makeStyles(theme => ({
  root: {
    overflowY: 'hidden',
    width: '100%',
  },
  titleBox: {
    display: 'flex',
    alignItems: 'center',
    padding: '9px 0px 9px 16px',
  },
  title: {
    flexGrow: 1,
    textAlign: 'left',
    paddingLeft: '1.0rem',
    fontSize: '1.125rem',
    fontWeight: 'bold',
    lineHeight: '1.25rem',
    color: theme.palette.tertiary.main,
  },
  closeIcon: {
    fontWeight: 200,
    fontSize: '1.25rem',
    color: theme.palette.tertiary.main,
  },
  propsRoot: {
    overflowY: 'auto',
  },
  nofill4x16: {
    fill: 'none',
    width: 4,
    height: 16,
  },
  fs62: {
    fontSize: '0.625rem',
  },
  fs87: {
    fontSize: '0.875rem',
  },
  fs100: {
    fontSize: '1.0rem',
  },
  mr25: {
    marginRight: '0.25rem',
  },
  mr50: {
    marginRight: '0.5rem',
  },
  mr100: {
    marginRight: '1.0rem',
  },
  mw62: {
    minWidth: '0.625rem',
  },
  closeBtn: {
    padding: '0px 0px 0px 4px',
  },
  editIcon: {
    fontSize: '0.875rem',
  },
  nofill10x6: {
    fill: 'none',
    width: 10,
    height: 6,
    marginRight: 8,
  },
  nofill12x15: {
    fill: 'none',
    width: 12,
    height: 15,
    marginLeft: 8,
  },
  nofill17x19: {
    fill: 'none',
    width: 17,
    height: 19,
    marginLeft: 8,
  },
  nofill16x16: {
    fill: 'none',
    width: 16,
    height: 16,
    marginLeft: 9,
  },
  nofill17x17: {
    fill: 'none',
    width: 17,
    height: 17,
    marginLeft: 9,
  },
  nofill28x28: {
    fill: 'none',
    width: 28,
    height: 28,
  },
  ellipsisIcon: {
    fontSize: '1.0rem',
    minWidth: '16px',
  },
  ellipsisIconDiv: {
    backgroundColor: '#FFFFFF',
    borderRadius: '50%',
    padding: '4px',
    // height: '24px',
  },
  grpIcon: {
    fill: 'currentColor',
  },
  geometryBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    width: theme.spacing(8),
    marginRight: theme.spacing(0.5),
    backgroundColor: theme.palette.info.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.info.dark,
    },
  },
  deleteBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    width: theme.spacing(8),
    marginRight: theme.spacing(0.5),
    backgroundColor: theme.palette.error.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.error.dark,
    },
  },
  applyBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    width: theme.spacing(8),
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  cancelBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    width: theme.spacing(8),
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.tertiary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.tertiary.dark,
    },
  },
  editBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'bold',
    color: '#828282',
  },
  seeMoreBtn: {
    textTransform: 'none',
    fontSize: '0.875rem',
    fontWeight: 'normal',
    marginLeft: theme.spacing(1),
    backgroundColor: theme.palette.primary.main,
    color: '#FFFFFF',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  bottomDivider: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}))

const Properties = (props) => {
  const showAlert = useCommonStore(state => state.showAlert)
  const accessGranted = useCommonStore(state => state.accessGranted)
  const currentAgriculturalPeriod = useCommonStore(state => state.currentAgriculturalPeriod)
  const measurementsManager = MeasurementsManager()
  const isFeatureDetailsDocked = (props.isFeatureDetailsDocked || props.isFeatureDetailsDocked === false) ? props.isFeatureDetailsDocked : useUiStore(state => state.isFeatureDetailsDocked)

  const classes = useStyles()
  const { t } = useTranslation()
  const featureService = FeatureService()
  const switchSlotService = SwitchSlotService()
  const cultivationService = CultivationService()
  const consumerService = ConsumerService()
  const cultivations = useCollectionStore(state => state.cultivations)
  const consumers = useCollectionStore(state => state.consumers)
  const switchSlots = useCollectionStore(state => state.switchSlots)
  const networkElementCollection = useFeatureCollection()
  const [openEditDialog, setOpenEditDialog] = useState(false)

  const {
    getStationCodeFromItemCode,
    getWszoneCodeFromItemCode,
  } = useGroupItemsUtils()

  const ChevronIcon = (ciprops) => {
    return ciprops.open ? (
      <SvgIcon viewBox="0 0 10 6" classes={{root: classes.nofill10x6,}}>
        <path d="M9 4.89941L5.1 1.09941" stroke="#BABABA" strokeWidth="1.9843" strokeLinecap="round"/>
        <path d="M5.1 1.1L1.2 5" stroke="#BABABA" strokeWidth="1.9843" strokeLinecap="round"/>
      </SvgIcon>
    ) : (
      <SvgIcon viewBox="0 0 10 6" classes={{root: classes.nofill10x6,}}>
        <path d="M1 1.1001L4.9 4.9001" stroke="#BABABA" strokeWidth="1.9843" strokeLinecap="round"/>
        <path d="M4.89999 4.9L8.79999 1" stroke="#BABABA" strokeWidth="1.9843" strokeLinecap="round"/>
    </SvgIcon>
    )
  }

  const handleOnEditClick = () => {
    //console.log(`handleOnEditClick`)
    setOpenEditDialog(true)
  }
  const handleOnEditOnMapClick = () => {
    // console.log(`handleOnEditOnMapClick`)
    props.actEditGeometry()
  }
  const handleOnSeeMoreClick = () => {
    // console.log(`handleOnSeeMoreClick`)
    props.handleDockClick()
  }

  const validateIrrigationPoint = async (feature) => {
    console.log(`validateIrrigationPoint`)
    console.log(feature)
    let valid = true
    let messages: string[] = []
    let isNewFeature = !feature._id
    if (!isNewFeature) {
      let existingIrrigationPoint = networkElementCollection.switches?.find(f => f._id === feature._id)
      if (existingIrrigationPoint) {
        if (feature.properties.switchType && feature.properties.switchType !== existingIrrigationPoint.properties.switchType) {
          valid = false
          let hourmeters = switchSlots
          if (!hourmeters) {
            hourmeters = await switchSlotService.getEntities().data
          }
          valid = !hourmeters || hourmeters.length === 0 || !hourmeters.some(hm => hm.switchCode === existingIrrigationPoint?.properties.code)
        }
      }
    }
    if (!valid) {
      let message = t('switch.validation.switchType.hourmetersExist', {val:feature.properties.label})
      messages.push(message)
      showAlert(messages.join('\n'), 'E')
    }
    // return valid
    return Promise.resolve(valid)
  }
  const validatePipe = (feature) => {
    console.log(`validatePipe`)
    console.log(feature)
    let valid = true
    let messages: string[] = []
    let isNewFeature = !feature._id
    if (!isNewFeature) {
      let existingFeature = networkElementCollection[`${feature.properties.featureType}s`]?.find(f => f._id === feature._id)
      if (existingFeature) {
        let pipeCoords = feature.geometry.coordinates
        if (feature.properties.inNodeCode && feature.properties.inNodeCode !== existingFeature.properties.inNodeCode) {
          let node = networkElementCollection[`${feature.properties.inNodeFeatureType}s`]?.find(f => f.properties.code === feature.properties.inNodeCode)
          // if (node) {
          //   //Scenario #1 replaces the pipes last or first vertex with the node's coordinates
          //   feature.properties.flowDirection  === 'normal' ?
          //     pipeCoords.splice(0, 1,node.geometry.coordinates) :
          //     pipeCoords.splice(pipeCoords.length -1, 1,node.geometry.coordinates)
          // }
          if (node) {
            //Scenario #2 adds an extra vertex (node's coordinates) to the beginning/end of the pipe
            // feature.geometry.coordinates.splice(0, 0, node.geometry.coordinates)
            feature.properties.flowDirection  === 'normal' ?
            pipeCoords.splice(0, 0,node.geometry.coordinates) :
            pipeCoords.push(node.geometry.coordinates)
          }
        }
        if (feature.properties.outNodeCode && feature.properties.outNodeCode !== existingFeature.properties.outNodeCode) {
          let node = networkElementCollection[`${feature.properties.outNodeFeatureType}s`]?.find(f => f.properties.code === feature.properties.outNodeCode)
          // if (node) {
          //   //Scenario #1 replaces the pipes last or first coordinate with the node's coordinate
          //   feature.properties.flowDirection  === 'normal' ?
          //     pipeCoords.splice(pipeCoords.length -1, 1,node.geometry.coordinates) :
          //     pipeCoords.splice(0, 1,node.geometry.coordinates)
          // }
          if (node) {
            //Scenario #2 adds an extra vertex (node's coordinates) to the beginning/end of the pipe
            // feature.geometry.coordinates.push(node.geometry.coordinates)
            feature.properties.flowDirection  === 'normal' ?
            pipeCoords.push(node.geometry.coordinates) :
            pipeCoords.splice(0, 0,node.geometry.coordinates)
          }
        }
      }
    }
    if (!valid) {
      showAlert(messages.join('\n'), 'E')
    }
    // return valid
    return Promise.resolve(valid)
  }
  const validateTank = (feature) => {
    let valid = true
    let messages: string[] = []
    let message
    let fp = feature.properties
    //check tank hight properties
    let tankHeight = fp.tankHeight && !isNaN(fp.tankHeight) ? fp.tankHeight : 0
    let maxHeight = fp.maxHeight && !isNaN(fp.maxHeight) ? fp.maxHeight : 0
    let minHeight = fp.minHeight && !isNaN(fp.minHeight) ? fp.minHeight : 0
    console.log(`tank Heights ${tankHeight} ${maxHeight} ${minHeight}`)
    if (tankHeight < maxHeight) {
      valid = false
      message = t('tank.validation.tankHeight.maxHeight')
      messages.push(message)
    }
    if (maxHeight < minHeight) {
      valid = false
      message = t('tank.validation.maxHeight.minHeight')
      messages.push(message)
    }

    if (!!fp.tankType) {
      if (!fp.volume && !!fp.tankHeight) {
        let vol
        if (fp.tankType === 'rectangular' && !!fp.tankWidth && !!fp.tankLength) {
          vol = fp.tankHeight * fp.tankWidth * fp.tankLength
          if (!!fp.numberOfChambers) {
            vol = vol * fp.numberOfChambers
          }
        } else if (fp.tankType === 'cylindrical' && !!fp.tankWidth) {
          vol = fp.tankHeight * fp.tankWidth * fp.tankWidth * 0.25 * 3.141
          if (!!fp.numberOfChambers) {
            vol = vol * fp.numberOfChambers
          }
        }
        if (!!vol) {
          let format
          let fix = -1
          let group = info.groups.find(g => {fix = g.fields.findIndex(f => f.code === 'volume' && !!f.format); return fix > -1;})
          if (!!group && fix > -1) {
            format = group.fields[fix].format
          }
          if (format) {
            let fractionDigits = format.length - format.indexOf('.') - 1
            vol = parseFloat(vol.toFixed(fractionDigits))
          }
        }
        fp.volume = vol
      }
    }
    if (!valid) {
      showAlert(messages.join('\n'), 'E')
    }
    // return valid
    return Promise.resolve(valid)
  }
  const validateHydrometer = (feature) => {
    let valid = true
    let messages: string[] = []
    let message
    if (!!feature.properties.errorFunction) {
      console.log(`feature.properties.errorFunctionParams ${feature.properties.errorFunctionParams}`)
      let params = !!feature.properties.errorFunctionParams ? feature.properties.errorFunctionParams.trim().replace(/ /g, '') : ''
      if (!params) {
        valid = false
        message = t('hdrm.validation.required.errorFunctionParams')
        messages.push(message)
      } else {
        let regexp
        switch (feature.properties.errorFunction) {
          case 'constant':
            regexp = /^[0-9]+(\.[0-9]+)?$/
            break;
          case 'linearYearly':
            regexp = /^[0-9]+(\.[0-9]+)?$/
            break;
          case 'linearOtherPeriod':
            regexp = /^[0-9]+(\.[0-9]+)?,[0-9]+(\.[0-9]+)?$/
            break;
          case 'interval':
            regexp = /^(\([0-9]+(\.[0-9]+)?,[0-9]+(\.[0-9]+)?\),)+(\([0-9]+(\.[0-9]+)?,([0-9]+(\.[0-9]+)?)?\))$/
            break;
          default:
            break;
        }
        let rest = params.replace(regexp, '')
        valid = !rest
        if (!valid) {
          message = t('hdrm.validation.invalid.errorFunctionParams')
          messages.push(message)
        }
        console.log(`params ${params} rest H${rest}H valid ${valid}`)
      }
    }
    if (!valid) {
      showAlert(messages.join('\n'), 'E')
    }
    // return valid
    return Promise.resolve(valid)
  }
  const validationFunctions = {
    hydrometer: validateHydrometer,
    pipe: validatePipe,
    tank: validateTank,
    switch: validateIrrigationPoint,
  }
  const validateFeature = async (feature) => {
    let valid = true
    let featureType = feature.properties.featureType
    if (!!validationFunctions[featureType]) {
      valid = await validationFunctions[featureType](feature)
    }
    // return valid
    return Promise.resolve(valid)
  }

  const computeScadaCodesCount = (feature) => {
    let cnt: any = ''
    if (!!feature.series) {
      cnt = feature.series.filter(s => !!s.scadaDeviceCode).length
      return cnt
    } else if (!!measurementsManager.getFeatureSeries) {
      cnt = '...'
      measurementsManager.getFeatureSeries(feature)
      .catch(err => {
        console.log(`ERROR computeScadaCodesCount err ${err}`)
      })
      return cnt
    } else {
      return cnt
    }
  }
  const computeCultivationsCount = (feature) => {
    let cnt: any = ''
    if (!!cultivations) {
      cnt = cultivations.filter(s => s.landparcelCode === feature.properties.code).length
      return cnt
    } else {
      cnt = '...'
      let agriculturalPeriodFilter = {
        cultivationPeriod: currentAgriculturalPeriod?.cultivationPeriod || -1
      }
      cultivationService.getEntities(agriculturalPeriodFilter)
      .catch(err => {
        console.log(`ERROR computeCultivationsCount err ${err}`)
      })
      return cnt
    }
  }
  const computeLandparcelOwners = (feature) => {
    let owners: any = ''
    if (!!consumers) {
      if (feature?.properties.ownerCodes && feature?.properties.ownerCodes.length > 0) {
        owners = consumers
        .filter(c => feature.properties.ownerCodes.includes(c.code))
        .map(c => `${c.lastname} ${c.firstname} ${c.middlename}`)
        .join(', ')
      }
    } else {
      owners = '...'
      consumerService.getEntities()
      .catch(err => {
        console.log(`ERROR computeLandparcelOwners err ${err}`)
      })
    }
    return owners
  }
  const doCompute = (computationName: string, feature: any) => {
    const computationFunctions = {
      computeScadaCodesCount: computeScadaCodesCount,
      computeCultivationsCount: computeCultivationsCount,
      computeLandparcelOwners: computeLandparcelOwners,
    }
    let computationFunction = computationFunctions[computationName]
    if (!!computationFunction) {
      return computationFunction(feature)
    } else {
      return 'No computation function provided for computed field!'
    }
  }

  const handleCloseEditDialog = (feature) => {
    // console.log(`handleCloseDialog : ${JSON.stringify(feature)}`)
    if (!!feature) {

      // let valid = validateFeature(feature)
      validateFeature(feature).then(
        res => {
          let valid = res
          if (!valid) {
            // setOpenEditDialog(false)
          } else {
            setOpenEditDialog(false)
            featureService.saveFeature(feature).then(
              res => {
                // console.log(`handleCloseDialog success : ${JSON.stringify(res.data)}`)
                // setOpenEditDialog(false)
              }
              ,err => {
                if (!!err.response && !!err.response.data && !!err.response.data.error) {
                  console.error(`handleCloseDialog error ${JSON.stringify(err.response.data.error,null,2)}`)
                } else {
                  console.log(`handleCloseDialog err ${err}`)
                }
                // console.log(`handleCloseDialog error : ${JSON.stringify(err)}`)
                //do nothing
              }
            )
          }
        }
      )
    } else {
      setOpenEditDialog(false)
    }
  }

  const getListValue = (value: any, values: any): any => {
    // console.log(`getTransKey values:${JSON.stringify(values)}`)
    let o = Object.values<any>(values).find(v => { return v.code === value })
    let result = (!!o) ? t(`${o.transKey}.${o.code}`) : `Missing ref key: ${value}`
    // console.log(`*** transkey is :${result}`)
    return result
  }

  const getDynamicListValue = (value: any, featureTypes: any): any => {
    let feature
    featureTypes.some(ft => {
      feature = networkElementCollection[ft]?.find(f => f.properties.code === value)
      return feature
    })
    let result = !!feature ? feature.properties.label : `Broken ref key: ${value}`
    return result
  }

  const formatValue = (val, info) => {
    // console.log(`formatValue val ${val}`)
    let fval = val
    if (!!val && !!info.format) {
      switch (info.type) {
        case 'date':
          fval = moment(val).format(t(info.format)!)
          // fval = moment(val).format(info.format)
          break;
        case 'number':
        case 'posnumber':
          fval = parseFloat(val)
          if (!isNaN(fval)) {
            if (info.type === 'posnumber' && fval < 0) {
              fval = -fval
            }
            let options: any = {}
            if (typeof info.format === 'string') {
              let fractionDigits = info.format.length - info.format.indexOf('.') - 1
              // fval = fval.toFixed( (info.format.length - info.format.indexOf('.') - 1) )
              options = {
                minimumFractionDigits: fractionDigits,
                maximumFractionDigits: fractionDigits,
              }
            } else {
              // let options: any = {}
              if (!!info.format.decimals || info.format.decimals === 0) {
                options.minimumFractionDigits = info.format.decimals
                options.maximumFractionDigits = info.format.decimals
              }
              if (!!info.format.unit) {
                options.style = 'unit'
                options.unit = info.format.unit
              } else if (!!info.format.currency) {
                options.style = 'currency'
                options.unit = info.format.currency
              }
            }
            // const lang = navigator.language
            // console.log(`lang ${lang}`)
            // fval = new Intl.NumberFormat(lang, options).format(fval)
            fval = new Intl.NumberFormat('el', options).format(fval)
          }
          break;
        default:
      }
    }
    // console.log(`formatValue formatted ${val}`)
    return fval
  }

  const Item = iprops => {
    // console.log(`iprops ${JSON.stringify(iprops,null,2)}`)
    // const value = !!iprops.feature.properties[iprops.info.code] ? iprops.feature.properties[iprops.info.code] : ''

    // let value = !!iprops.info.nested ? iprops.feature[iprops.info.nested][iprops.info.code] : iprops.feature[iprops.info.code]
    let value: null | any = null
    if (!iprops.info.nested) {
      value = iprops.feature[iprops.info.code]
    } else {
      let pathElements = iprops.info.nested.split('.')
      let parent = iprops.feature
      pathElements.forEach(p => {
        if (!parent[p]) {
          parent[p] = {}
        }
        parent = parent[p]
      })
      if (iprops.info.code === 'stationCode') {
        value = getStationCodeFromItemCode(parent.code)
      } else if (iprops.info.code === 'wszoneCode') {
        value = getWszoneCodeFromItemCode(parent.code)
      } else {
        value = parent[iprops.info.code]
      }
    }

    let isComputed = iprops.info.type === 'computed'
    if (isComputed) {
      // let computationFunction = computationFunctions[iprops.info.computation]
      value = doCompute(iprops.info.computation, iprops.feature)
    } else if (!value) {
      value = ''
    } else {
      let isList = iprops.info.type === 'selectionList'
      let isDynamicSelectionList = iprops.info.type === 'dynamicSelectionList'
      let isGroupedDynamicSelectionList = iprops.info.type === 'groupedDynamicSelectionList'
      if (isList) {
        value = t(getListValue(value, iprops.info.values))
      } else if (isDynamicSelectionList) {
        // value = getDynamicListValue(value, props[iprops.info.values])
        value = getDynamicListValue(value, iprops.info.featureTypes)
      } else if (isGroupedDynamicSelectionList && !iprops.info.groupedBy) {
        value = getDynamicListValue(value, iprops.info.dynamicFeatureTypes)
      } else if (isGroupedDynamicSelectionList && iprops.info.groupedBy) {
        value = t(`net.${value}`)
      } else if (!!iprops.info.format) {
        value = formatValue(value, iprops.info)
      } else if (iprops.info.type === 'boolean') {
        value = !!value ? t('btn.yes') : t('btn.no')
      }
    }
    let label =t(iprops.info.label)
    if (!!iprops.info.unit) {
      label = `${label} (${t(iprops.info.unit)})`
    }

    // console.log(`field ${iprops.field} editedField ${iprops.editingProp} editing ${editing}`)
    // console.log(`iprops.info.code ${iprops.info.code} value ${value}`)
    return (
      <ListItem key={iprops.info.code}
        className='item'
      >
        <ListItemText
          className='txt lab'
          primary={label}
        />
        {<div
          className={`valbox${' sleep'}`}
        >
          <ListItemText
            className='txt val'
            primary={value}
          />
        </div>}
      </ListItem>
    )
  }

  const CollapsibleProps = cprops => {
    const [open, setOpen] = useState(true)
    let editing = cprops.editingProp && cprops.info.findIndex(f => f.code === cprops.editingProp) > -1
    return (
      <List
        className='tankProperties'
      >
        {isFeatureDetailsDocked && <ListItem key='1'
          button={cprops.group.collapsible}
          onClick={() => editing || !cprops.group.collapsible ? null : setOpen(!open)}
          className='item headerItem'
        >
          <WrmIcon
            icon={cprops.group.icon}
            className={classes.grpIcon}
          />
          <ListItemText
            className='txt'
            primary={t(cprops.group.label)}
          />
          {cprops.group.collapsible && <ChevronIcon open={open} alt='' />}
        </ListItem>}
        {isFeatureDetailsDocked && open && <Divider variant='middle' />}
        {open && cprops.info
        .filter(f => !f.hidden && (isFeatureDetailsDocked || f.docked))
        .map(f => {
          if (!f.nested && !!cprops.group.nested) f.nested = cprops.group.nested
          if (f.type === 'groupedDynamicSelectionList' && !f.groupedBy) {
            let featureTypeInfo = cprops.info.find(p => p.aggregates === f.code)
            f.dynamicFeatureTypes = featureTypeInfo.selectionListGroups.map(ft => ft.values)
          }
          return (
            <Item
              key={f.code}
              info={f}
              feature={cprops.feature}
              isReadOnly={cprops.isReadOnly}
            />
          )
        })}
      </List>
    )
  }

  /** This function adds new (or modifies existing) properties customised per tenant.
  * The functionality is no longer in use, but we keep it here in case it becomes useful in the future. */
  const mergePropertiesSettings = (defaults, customs) => {
    if (!!customs) {
      // console.log(`defaults ${JSON.stringify(defaults,null,2)}`)
      // console.log(`customs ${JSON.stringify(customs,null,2)}`)
      let flatDefaults = defaults.groups.flatMap(g => g.fields)
      // console.log(`flatDefaults ${JSON.stringify(flatDefaults,null,2)}`)
      customs.items.forEach(cs => {
        let ds = flatDefaults.find(d => d.code === cs.code)
        if (!ds && cs.custom) {
          //for starters, let's just put the custom field in the last group
          cs.nested = 'properties.customs'
          defaults.groups[defaults.groups.length - 1].fields.push(cs)
        } else if (!!ds) {
          // let ds = flatDefaults.find(d => d.code === cs.code)
          if (!!cs.label) ds.label = cs.label
          if (!!cs.type) ds.type = cs.type
          if (!!cs.format) ds.format = cs.format//maybe do some validation here based on type
          ds.readonly = cs.readonly
          ds.hidden = cs.hidden
          ds.docked = cs.docked
          // if (!!cs.readonly) ds.readonly = cs.readonly === 'yes'
          // if (!!cs.hidden) ds.hidden = cs.hidden === 'yes'
          // if (!!cs.docked) ds.docked = cs.docked === 'yes'
        }
      })
      console.log(`customised settings ${JSON.stringify(defaults,null,2)}`)
    }
    return defaults
  }
  // const info = !!props.feature ? FEATURE_PROPERTIES[props.feature.properties.featureType] : null
  const featureType = !!props.feature ? props.feature.properties.featureType : null
  const isFreeDrawnFeature = !!props.feature && !props.feature.properties.featureType
  const info = !!featureType ? FEATURE_PROPERTIES[featureType] : (isFreeDrawnFeature ? DrawnLayerProperties[props.feature.geometry.type] : null)
  // const info = !!featureType
  //   ? mergePropertiesSettings(FEATURE_PROPERTIES[featureType], !!props.propertiesSettings ? props.propertiesSettings.find(ps => ps.code === `${featureType}Properties`) : null)
  //   : (isFreeDrawnFeature ? DrawnLayerProperties[props.feature.geometry.type] : null)
  const isReadOnly = !accessGranted('dtprpr')
  return (
    <Box
      display='flex'
      flexDirection='column'
      flexGrow={1}
      className={clsx(classes.root, 'tankPropList')}
    >
      <Box
        display='flex'
        flexDirection='column'
        flexGrow={1}
        className={classes.propsRoot}
      >
      {!!props.feature && !!info && info.groups
        .filter(g => (isFeatureDetailsDocked || g.docked) && !g.hidden)
        .map(g => (
        <CollapsibleProps
          key={g.code}
          group={g}
          info={g.fields}
          feature={props.feature}
          isReadOnly={isReadOnly}
        />
      ))}
      </Box>
      {!props.readonly && !isFreeDrawnFeature && <Divider
        variant='middle'
        className={classes.bottomDivider}
      />}
      <Box
        display='flex'
        justifyContent='flex-end'
      >
        {!props.readonly && isFeatureDetailsDocked && !!props.feature && !isFreeDrawnFeature &&
        <Button variant='outlined'
          className={classes.editBtn}
          onClick={handleOnEditClick}
          disabled={false}
        >
          {t('btn.editProperties')}
        </Button>}
        {!props.readonly && !isFeatureDetailsDocked && !!props.feature && !isFreeDrawnFeature &&
        <Button
          variant='outlined'
          className={classes.editBtn}
          onClick={handleOnEditOnMapClick}
        >
          {t('btn.editLocationOnMap')}
        </Button>}
        {!isFeatureDetailsDocked && !!props.feature && !isFreeDrawnFeature &&
        <Button
          className={classes.seeMoreBtn}
          onClick={handleOnSeeMoreClick}
        >
          {t('btn.seeMore')}
        </Button>}
      </Box>
       {!!props.feature && openEditDialog && <EditPropertiesDialog
        open={openEditDialog}
        onClose={handleCloseEditDialog}
        feature={props.feature}
        info = {info}
      />}
    </Box>
  )
}
export default Properties
