import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useCollectionStore } from '../state/collectionStore'
import useFeatureCollection from './useFeatureCollection'
import {
  EquipmentCategory,
  FTC,
  GroupItemTypes,
} from '../utils/constants'

const useGroupItemsUtils = () => {
  const { t } = useTranslation()

  const assets = useCollectionStore(state => state.assets) || []
  const Features = useFeatureCollection()

  const groupFeatureTypes: string[] = Object.values(FTC).filter((ftc: any) => ftc.isGroup).map((ftc: any) => ftc.code)
  const groupFeatureCollectionNames: string[] = Object.values(FTC).filter((ftc: any) => ftc.isGroup).map((ftc: any) => ftc.collection)
  const itemFeatureTypes: string[] = Object.values(FTC).map((ftc: any) => ftc.code)
  const itemFeatureCollectionNames: string[] = Object.values(FTC).map((ftc: any) => ftc.collection)

  const groupsLookupMap = useMemo(() => {
    const lookupMap = new Map<string, any>()
    groupFeatureCollectionNames.forEach((collname) => {
      Features[collname]?.forEach((itm: any) => (
        lookupMap.set(itm.properties.code, {code: itm.properties.code, label: itm.properties.label, type: `net.${itm.properties.featureType}.plural`})
      ))
    })
    return lookupMap
  }, [Features])
  const groupsLookup = useMemo(() => {
    return Array.from(groupsLookupMap.values())
  }, [groupsLookupMap])

  const itemsLookupMap = useMemo(() => {
    const lookupMap = new Map<string, any>()
    itemFeatureCollectionNames.forEach((collname: string) => {
      Features[collname]?.forEach((itm: any) => (
        lookupMap.set(itm.properties.code, {code: itm.properties.code, label: itm.properties.label, type: `net.${itm.properties.featureType}.plural`})
      ))
    })
    assets.forEach(itm => lookupMap.set(itm.code, {code: itm.code, label: itm.label, type: `equipment.category.${itm.category}`}))
    return lookupMap
  }, [assets, Features])
  const itemsLookup = useMemo(() => {
    return Array.from(itemsLookupMap.values())
  }, [itemsLookupMap])

  const groupTypesLookup = groupFeatureTypes.map((ft: string) => ({ code: ft, label: t(`net.${ft}`) }))

  const itemTypesLookup = itemFeatureTypes.map((ft: string) => ({ code: ft, label: t(`net.${ft}`) }))
  .concat(Object.values(EquipmentCategory).map(c => ({ code: c.code, label: t(`${c.transKey}.${c.code}`) })))

  const groupItemTypesLookup = Object.values(GroupItemTypes).map(c => ({ code: c.code, label: t(`${c.transKey}.${c.code}`) }))

  const getGroupOrItemTypeFromCode = (code: string) => {
    let deviceLookupVal: any = groupsLookupMap.get(code)
    if (!deviceLookupVal) {
      deviceLookupVal = itemsLookupMap.get(code)
    }
    if (!deviceLookupVal?.type) return
    deviceLookupVal = deviceLookupVal.type?.split('.')
    if (deviceLookupVal?.length !== 3) return
    /* type is either net.XXX.plural or equipment.category.XXX */
    let type = deviceLookupVal[0] === 'net' ? deviceLookupVal[1] : deviceLookupVal[2]
    return type
  }

  const getGroupOrItemFromCode = (code: string, type?: string) => {
    if (!type) {
      type = getGroupOrItemTypeFromCode(code)
    }
    let device: any
    if (Features[FTC[type!]?.collection]) {
      device = Features[FTC[type!].collection].find(itm => itm.properties.code === code)
    } else {
      device = assets.find(itm => itm.code === code)
    }
    return device
  }

  const getGroupCodeFromItemCode = (itemCode: string, groupType: string) => {
    let groupItemsByItemMap: Map<string, any[]> = useCollectionStore.getState().groupItemsByItemMap
    let groupItems = groupItemsByItemMap.get(itemCode)
    if (!groupItems) return
    let groupItem = groupItems.find(gi => gi.itemCode === itemCode && gi.groupType === groupType)
    return groupItem?.groupCode
  }

  const getStationCodeFromItemCode = (itemCode: string) => {
    return getGroupCodeFromItemCode(itemCode, 'station')
  }
  const getWszoneCodeFromItemCode = (itemCode: string) => {
    return getGroupCodeFromItemCode(itemCode, 'wszone')
  }

  const validateEditedGroupItem = (editedItem: any, originalItem: any): string[] => {
    const groupItems = useCollectionStore.getState().groupItems || []
    let messages: string[] = []

    //TODO: check circular control references

    /* special considerations for stations  */
    const groupType = editedItem.groupType
    if (editedItem.groupType === 'station') {

      /* Actually the next two checks are also implemented (below) for all other group types as well.
      * If it turns out that no special handling id required then remove those checks and let the 'universal'
      * group checks do the job */

      /* a station may not contain other stations (directly at least) */
      if (editedItem.itemType === 'station') {
        let errmsg = 'a station may not contain other stations (directly at least)'
        messages.push(errmsg)
      }

      /* an item may be included only in one station (directly at least) */
      let conflictingItems = groupItems.filter(gi => 
          gi.itemCode === editedItem.itemCode
          && gi.groupType === 'station'
          && gi.code !== editedItem.code// exclude the item being edited
      )
      if (conflictingItems.length > 0) {
        let errmsg = 'an item may be included only in one station (directly at least)'
        messages.push(errmsg)
      }


    } else {

      /* a group may not contain other groups of the same type (directly at least) */
      if (editedItem.itemType === groupType) {
        let errmsg = `a ${groupType} may not contain other ${groupType}s (directly at least)}`
        messages.push(errmsg)
      }

      /* for any given group type, an item may be included only in on group of that type (directly at least) */
      let conflictingItems = groupItems.filter(gi => 
          gi.itemCode === editedItem.itemCode
          && gi.groupType === groupType
          && gi.code !== editedItem.code// exclude the item being edited
      )
      if (conflictingItems.length > 0) {
        let errmsg = `an item may be included only in one ${groupType} (directly at least)}`
        messages.push(errmsg)
      }

    }
    
    return messages
  }

  return {
    getGroupOrItemFromCode,
    getGroupOrItemTypeFromCode,
    groupsLookup,
    groupTypesLookup,
    itemsLookup,
    itemTypesLookup,
    groupItemTypesLookup,
    getStationCodeFromItemCode,
    getWszoneCodeFromItemCode,
    getGroupCodeFromItemCode,
    validateEditedGroupItem,
  }

}

export default useGroupItemsUtils