import TemplateService from './TemplateService'
import { useCollectionStore } from '../state/collectionStore'

const GroupItemService = () => {

  const templateService: any = TemplateService('groupItem')

  const getEntities = (filter?: any, options?: any) => {
    return templateService.getEntities(filter, options)
    .then(
      res => {
        let groupItemsByGroupMap: Map<string, any[]> = useCollectionStore.getState().groupItemsByGroupMap
        if (groupItemsByGroupMap.size === 0 || options?.doReload) {
          groupItemsByGroupMap = new Map<string, any[]>()
          res.data.forEach((gi: any) => {
            let groupItems = groupItemsByGroupMap.get(gi.groupCode)
            if (!groupItems) {
              groupItems = []
              groupItemsByGroupMap.set(gi.groupCode, groupItems)
            }
            groupItems.push(gi)
          })
          useCollectionStore.getState().setItems('groupItemsByGroupMap', groupItemsByGroupMap)
        }
        let groupItemsByItemMap: Map<string, any[]> = useCollectionStore.getState().groupItemsByItemMap
        if (groupItemsByItemMap.size === 0 || options?.doReload) {
          groupItemsByItemMap = new Map<string, any[]>()
          res.data.forEach((gi: any) => {
            let groupItems = groupItemsByItemMap.get(gi.itemCode)
            if (!groupItems) {
              groupItems = []
              groupItemsByItemMap.set(gi.itemCode, groupItems)
            }
            groupItems.push(gi)
          })
          useCollectionStore.getState().setItems('groupItemsByItemMap', groupItemsByItemMap)
        }

        return res
      }
    )
  }
  const saveEntity = (entity: any, prevEntity: any, doNotSelect?: boolean) => {
    return templateService.saveEntity(entity, doNotSelect)
    .then(
      res => {
        let groupItemsByGroupMap: Map<string, any[]> = useCollectionStore.getState().groupItemsByGroupMap
        let groupItems = groupItemsByGroupMap.get(entity.groupCode)
        if (!groupItems) {
          groupItems = []
          groupItemsByGroupMap.set(entity.groupCode, groupItems)
        }
        let idx = groupItems.findIndex(gi => gi.code === entity.code)
        if (idx === -1) {
          groupItems.push(res)
          // check if the prevEntity is in the groupItemsByGroupMap and remove it
          if (prevEntity) {
            let prevGroupItems = groupItemsByGroupMap.get(prevEntity.groupCode)
            if (prevGroupItems) {
              let prevIdx = prevGroupItems.findIndex(gi => gi.code === prevEntity.code)
              if (prevIdx !== -1) {
                prevGroupItems.splice(prevIdx, 1)
              }
            }
          }
        } else {
          groupItems[idx] = res
        }
        useCollectionStore.getState().setItems('groupItemsByGroupMap', groupItemsByGroupMap)

        let groupItemsByItemMap: Map<string, any[]> = useCollectionStore.getState().groupItemsByItemMap
        groupItems = groupItemsByItemMap.get(entity.itemCode)
        if (!groupItems) {
          groupItems = []
          groupItemsByItemMap.set(entity.itemCode, groupItems)
        }
        idx = groupItems.findIndex(gi => gi.code === entity.code)
        if (idx === -1) {
          groupItems.push(res)
          // check if the prevEntity is in the groupItemsByItemMap and remove it
          if (prevEntity) {
            let prevGroupItems = groupItemsByItemMap.get(prevEntity.itemCode)
            if (prevGroupItems) {
              let prevIdx = prevGroupItems.findIndex(gi => gi.code === prevEntity.code)
              if (prevIdx !== -1) {
                prevGroupItems.splice(prevIdx, 1)
              }
            }
          }
        } else {
          groupItems[idx] = res
        }

        return res
      }
    )
  }
  const deleteEntity = (entity: any, note?: any) => {
    return templateService.deleteEntity(entity, note)
    .then(
      res => {
        let groupItemsByGroupMap: Map<string, any[]> = useCollectionStore.getState().groupItemsByGroupMap
        let groupItems = groupItemsByGroupMap.get(entity.groupCode)
        if (groupItems) {
          let idx = groupItems.findIndex(gi => gi.code === entity.code)
          if (idx !== -1) {
            groupItems.splice(idx, 1)
          }
        }
        useCollectionStore.getState().setItems('groupItemsByGroupMap', groupItemsByGroupMap)

        let groupItemsByItemMap: Map<string, any[]> = useCollectionStore.getState().groupItemsByItemMap
        groupItems = groupItemsByItemMap.get(entity.itemCode)
        if (groupItems) {
          let idx = groupItems.findIndex(gi => gi.code === entity.code)
          if (idx !== -1) {
            groupItems.splice(idx, 1)
          }
        }

        return res
      }
    )
  }

  return({
    getEntities: getEntities,
    saveEntity: saveEntity,
    deleteEntity: deleteEntity,
    // getEntities: templateService.getEntities,
    // saveEntity: templateService.saveEntity,
    // deleteEntity: templateService.deleteEntity,
    saveEntityNote: templateService.saveEntityNote,
    deleteEntityNote: templateService.deleteEntityNote,
  })

}

export default GroupItemService
